import { IRole } from 'hrm-common/extensions/interfaces/personel';
import { setItem, getJson, removeItem } from './storage';

export const setUser = (user: any) => {
  setItem('user', JSON.stringify(user));
};

export const getUser = () => {
  return getJson('user');
};

export const logout = () => {
  removeItem('user');
};

export const getToken = () => {
  const localUser = getUser();
  if (!localUser) {
    return null;
  }
  return localUser.accessToken;
};

export const removeStorage = () => {
  localStorage.removeItem('hrm.user');
  localStorage.removeItem('hrm.tenant');
  localStorage.removeItem('hrm.register');
  localStorage.removeItem('os_pageViews');
  localStorage.removeItem('isOptedOut');
  localStorage.removeItem('isPushNotificationsEnabled');
  localStorage.removeItem('onesignal-notification-prompt');
  localStorage.removeItem('persist:root');
  localStorage.removeItem('current_date_choose');
  localStorage.removeItem('isVerifyOTP');
};

export const getLanguage = () => {
  return getJson('language');
};

export const checkIsRoleSystemAdmin = (roles: IRole[]) => {
  return roles.some((role) => role.name === 'System Admin');
};

export const permisisonsLineManager = [
  'LEAVE_OFF_CREATE_REASON',
  'REQUEST_APPROVAL',
  'TIMEKEEPING_ACCESS_PERSONAL',
  'TIMESHEET_ACCESS_PERSONAL',
  'TIMESHEET_ADJUST_TIMECLOCK_FOR_EMPLOYEE',
  'TIMESHEET_APPROVE_ALL',
  'TIMESHEET_RESET_MONTH',
  'TIMESHEET_RESET_REQUEST',
  'TIMESHEET_SELF_APPROVE',
  'TIMESHEET_SYNCHRONIZED_DATA',
  'TIMESHEET_ADJUST_TIMECLOCK',
  'TIMESHEET_CANCEL_ADJUST_TIMECLOCK',
  'TIMESHEET_CANCEL_OVERTIME',
  'TIMESHEET_CANCEL_TIMEOFF',
  'TIMESHEET_CONFIRM',
  'TIMESHEET_OVERTIME_REQUEST',
  'TIMESHEET_TIMEOFF_REQUEST',
  'TIMESHEET_VIEW_OVERTIME',
  'TIMESHEET_VIEW_PERSONAL',
  'TIMESHEET_VIEW_TIMECLOCK',
  'TIMESHEET_VIEW_TIMEOFF',
  'TIMESHEET_MANAGE_EMPLOYEE',
  'TIMESHEET_MONTH_APPROVAL',
  'TIMESHEET_REQUEST_APPROVAL',
  'TIMESHEET_EXPORT',
  'TIMESHEET_VIEW_EMPLOYEE',
  'TIMESHEET_ADJUST_TIMECLOCK_APPROVAL',
  'TIMESHEET_OVERTIME_APPROVAL',
  'TIMESHEET_OVERVIEW_LIST_REQUEST',
  'TIMESHEET_TIMEOFF_APPROVAL',
  'TIMESHEET_VIEW_LIST_REQUEST',
  'TIMESHEET_ACCESS_OBSERVATION_LIST',
  'TIMESHEET_OBSERVATION_VIEW_OVERTIME',
  'TIMESHEET_OBSERVATION_VIEW_TIMEOFF',
  'TIMESHEET_VIEW_OBSERVATION',
  'TRAINING_ACCESS_PERSONAL',
  'TRAINING_MANAGE',
  'PERFORMANCE_ACCESS_PERSONAL',
  'VIEW_PERFORMANCE_REVIEW',
  'UPDATE_CYCLE_REVIEW',
];
