import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { loginWithPassword, loginWithMicrosoft, loginWithFirebase } from './loginAction';

export interface AuthState {
  isLoading: boolean;
  error: any | null;
  loggedIn: boolean;
  user: any | null;
  authFromCache: boolean;
  shouldShowSignoutMicrosoft: boolean;
  showMoney: boolean;
  isAdmin: boolean;
}

const initialState: AuthState = {
  isLoading: false,
  error: null,
  loggedIn: false,
  user: null,
  authFromCache: false,
  shouldShowSignoutMicrosoft: false,
  showMoney: false,
  isAdmin: true,
};

const auth = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: () => {},
    updateUser: (state, action: PayloadAction<any>) => {
      state.user = action.payload.user;
    },
    setShowMoney: (state, action: PayloadAction<boolean>) => {
      state.showMoney = action.payload;
    },
    setChangeRole: (state, action: PayloadAction<boolean>) => {
      state.isAdmin = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginWithPassword.pending, (state, action) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(loginWithPassword.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
        state.user = null;
      })
      .addCase(loginWithPassword.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        state.loggedIn = true;
        state.user = action.payload;
      })
      .addCase(loginWithMicrosoft.pending, (state, action) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(loginWithMicrosoft.rejected, (state, action) => {
        state.isLoading = false;

        state.error = action.payload;
        state.shouldShowSignoutMicrosoft = true;
        state.user = null;
      })
      .addCase(loginWithMicrosoft.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        state.loggedIn = true;
        state.user = action.payload;
      })
      .addCase(loginWithFirebase.pending, (state, action) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(loginWithFirebase.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
        state.shouldShowSignoutMicrosoft = true;
        state.user = null;
      })
      .addCase(loginWithFirebase.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        state.loggedIn = true;
        state.user = action.payload;
      });
  },
});

export const { logout, updateUser, setShowMoney, setChangeRole } = auth.actions;

export const selectUser = (state: RootState) => state.auth.user;

export const selectPermissions = (state: RootState) =>
  state.auth.user ? state.auth.user.permissions : [];

export const selectExtraPermissions = (state: RootState) =>
  state.auth.user ? state.auth.user.extraPermissions : [];
export const selectUserLoginId = (state: RootState) => (state.auth.user ? state.auth.user.id : '');
export const selectShowMoney = (state: RootState) => state.auth.showMoney;
export const selectLineOrAdmin = (state: RootState) => state.auth.isAdmin;

export default auth.reducer;
