import {
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Modal,
  Radio,
  Row,
  Select,
} from 'antd';
import { useAppSelector } from 'app/hooks';
import { colors } from 'assets/colors';
import { updateEmployeeInsurance } from 'features/employee/employeeAction';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { useDebouncedCallback } from 'use-debounce';
import { getUser } from 'utils/auth';
import {
  formatCurrencyUnit,
  formatDateInsurances,
  formatISDate,
  labelKeyStatus,
  labelUnit,
  stringCamelCase,
} from '../constants/common';
import { createScheduleInsurances, labelMessage } from '../constants/label';
import { createIncreaseDecrease, getEmployeeSearch } from '../insuranceAction';
import { selectInsurance } from '../insuranceSlice';
import { InfoButton } from './ModalEdit';
import { InfoU } from './ModalReject';
import { ContainerButton, InfoButtonReject } from './ModalView';

const { Option } = Select;
const { TextArea } = Input;

interface CreateModalProps {
  isVisible: boolean;
  isShowFullMenu: boolean;
  onClose: () => void;
  reSetData: () => void;
  activekey: string;
}

const mockVal = (data: any, repeat = 1) => ({
  value: data?.fullName,
  key: data?.id,
});

const ModalCreate = (props: CreateModalProps) => {
  const { isVisible, onClose, activekey = 'increase' } = props;
  const dispatch = useDispatch();
  const { t }: any = useTranslation(['insurance']);
  const [form] = Form.useForm();
  const insurance = useAppSelector(selectInsurance);
  const checkUserId = insurance?.dataCheckUserID?.data;
  const dataType = insurance?.dataTypeInsurance?.data?.result;
  const dataTypeOf = insurance?.dataTypeOf?.data;

  const [is_dataTypeOfIncrease, setISDataTypeOfIncrease] = useState(dataTypeOf?.increase);
  const [data, setData] = useState(dataType);
  const [iswidth] = useState(561);
  const [input_note, setInputNote] = useState();
  const [input_ID, setInputID] = useState();
  const [input_name, setInputName] = useState();
  const [input_position, setInputPosition] = useState();
  const [input_social, setInputSocial] = useState();
  const [input_validdate, setInputValidDate] = useState();
  const [input_current, setInputCurrrent] = useState(0);
  const [input_checkbox, setChecobox] = useState([1, 3, 2, 4]);
  const [input_TypeOf, setInputTypeOf] = useState();
  const [input_newsalary, setInputNewSalary] = useState(0);
  const [titleType, setTitleType] = useState();
  const [textTitle, setTextTitle] = useState();
  const [input_date, setInputDate] = useState();
  const [radiovalue, setRadioValue] = useState(createScheduleInsurances.INCREASE_PREMIUM);
  const [, setButtonDisabled] = useState(true);
  const [adjustFor, setadjustFor] = useState();
  const [checkedList, setCheckedList]: any = useState();
  const [options, setOptions] = useState([]);
  const [id_user, setIDUser] = useState([]);
  const [isSocialNumberAlready, setIsSocialNumberAlready] = useState(false);
  const [selectedUser, setSelectedUser] = useState();

  useEffect(() => {
    if (activekey === 'increase') {
      setCheckedList([1, 2, 3, 4]);
    } else {
      setCheckedList([]);
    }
  }, [activekey]);

  const debounced = useDebouncedCallback((value) => {
    onSearch(value);
  }, 350);

  useEffect(() => {
    if (dataType?.length > 0) {
      setData(dataType);
    }
  }, [dataType]);

  useEffect(() => {
    if (dataType) {
      setInputName(checkUserId?.username);
      setInputPosition(checkUserId?.positionName);
      setInputSocial(checkUserId?.socialInsuranceNumber);
      setInputValidDate(checkUserId?.currentValidDate);
      setInputCurrrent(checkUserId?.currentSalary === undefined ? 0 : checkUserId?.currentSalary);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkUserId]);

  useEffect(() => {
    if (radiovalue === createScheduleInsurances.INCREASE_PREMIUM) {
      setISDataTypeOfIncrease(dataTypeOf?.increase);
      setTitleType(createScheduleInsurances.INCREASE_PREMIUM);
      setTextTitle(`${t('increase_premium')}`);
      setInputTypeOf(dataTypeOf?.increase[0]);
      setadjustFor('increase');
    } else {
      setISDataTypeOfIncrease(dataTypeOf?.decrease);
      setTitleType(createScheduleInsurances.DECREASE_PREMIUM);
      setadjustFor('decrease');
      setTextTitle(`${t('decrease_premium')}`);
      setInputTypeOf(dataTypeOf?.decrease[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [radiovalue, dataTypeOf]);

  useEffect(() => {
    dispatch(
      getEmployeeSearch({ keyword: '', status: activekey === 'increase' ? 'approved' : '' }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClose = (close: boolean) => {
    if (activekey === 'decrease') {
      setCheckedList([]);
    } else {
      setCheckedList([1, 3, 2, 4]);
    }
    setInputName();
    setInputID();
    setInputPosition();
    setInputValidDate(null);
    setInputSocial();
    setInputCurrrent(0);
    setInputNote();
    setInputNewSalary(0);
    setIDUser([]);
    form.resetFields();

    if (close) {
      setTimeout(() => {
        // @ts-ignore
        onClose();
      }, 0);
    }
  };
  const onChange = (e: any) => {
    setChecobox(e);
    setCheckedList(e);
    if (activekey === 'decrease') {
      if (e.length > 0) {
        setInputNewSalary(Number(input_current));
        form.setFieldsValue({
          [labelMessage.MESSAGE_NEW_SALARY]: Number(input_current),
        });
      } else {
        setInputNewSalary(0);
        form.setFieldsValue({
          [labelMessage.MESSAGE_NEW_SALARY]: 0,
        });
      }
    }
  };
  const handleChange = (e: any) => {
    setInputTypeOf(e);
  };
  const handleRadio = (e: any) => {
    setRadioValue(e.target.value);
    form.validateFields();
  };
  const handleOnChangeDate = (date: any, dateString: any) => {
    setInputDate(formatISDate(date));
  };

  useEffect(() => {
    if (
      input_ID &&
      input_TypeOf &&
      input_newsalary >= 0 &&
      input_date &&
      input_date !== 'Invalid date' &&
      input_name !== undefined &&
      checkedList?.length > 0
    ) {
      if (radiovalue === createScheduleInsurances.INCREASE_PREMIUM && input_newsalary > 0) {
        setButtonDisabled(false);
      } else if (
        radiovalue === createScheduleInsurances.DECREASE_PREMIUM &&
        input_newsalary >= 0 &&
        input_newsalary !== null
      ) {
        setButtonDisabled(false);
      } else {
        setButtonDisabled(true);
      }
    } else {
      setButtonDisabled(true);
    }
  }, [
    input_ID,
    input_TypeOf,
    input_newsalary,
    input_note,
    input_date,
    input_name,
    input_checkbox,
    input_social,
    radiovalue,
    checkedList?.length,
  ]);

  const handleSendRequest = () => {
    switch (adjustFor) {
      case 'increase':
        if (Number(input_newsalary) >= Number(input_current)) {
          setCallAPiSendRequest();
        }
        break;
      case 'decrease':
        if (Number(input_newsalary) <= Number(input_current)) {
          setCallAPiSendRequest();
        }
        break;
    }
  };
  const setCallAPiSendRequest = () => {
    let listManagers: any = [];
    selectedUser?.managers.map((manager: any) =>
      listManagers.push({ id: manager.employeeId, name: manager.fullName }),
    );

    form.validateFields().then((value) => {
      dispatch(
        createIncreaseDecrease({
          adjustFor: adjustFor,
          typeOfPremium: input_TypeOf,
          newSalaryPremium: String(input_newsalary),
          newValidDate: input_date,
          status: labelKeyStatus.KEY_WAITING_FOR_APPROVAL,
          note: input_note,
          typeInsurance: checkedList,
          userId: id_user,
          createdBy: getUser()?.fullName,
          callBack: (response: any) => {
            if (!response.error) {
              dispatch(
                updateEmployeeInsurance({
                  isInsurance: true,
                  idItem: selectedUser.id,
                  dataRequest: {
                    ...selectedUser,
                    managers: listManagers,
                    socialInsuranceNumber: input_social,
                  },
                }),
              );
              handleClose(true);
              props.reSetData && props.reSetData();
            } else {
              setButtonDisabled(true);
            }
          },
        }),
      );
    });
  };
  const handleSaveDraft = () => {
    switch (adjustFor) {
      case 'increase':
        if (Number(input_newsalary) >= Number(input_current)) {
          setCallAPiSaveDraftt();
        }
        break;
      case 'decrease':
        if (Number(input_newsalary) <= Number(input_current)) {
          setCallAPiSaveDraftt();
        }
        break;
    }
  };
  const setCallAPiSaveDraftt = () => {
    form.validateFields().then((value) => {
      dispatch(
        createIncreaseDecrease({
          adjustFor: adjustFor,
          typeOfPremium: input_TypeOf,
          newSalaryPremium: String(input_newsalary),
          newValidDate: input_date,
          status: labelKeyStatus.KEY_DRAFT,
          note: input_note,
          typeInsurance: input_checkbox,
          userId: id_user,
          createdBy: getUser()?.fullName,
          callBack: (response: any) => {
            if (!response.error) {
              props.reSetData && props.reSetData();
              handleClose(true);
            } else {
              setButtonDisabled(true);
            }
          },
        }),
      );
    });
  };
  const onFinish = (values: any) => {
    console.log(values);
  };
  const handleNewSalary = (value: any) => {
    setInputNewSalary(value);
    form.validateFields();
  };

  const validatorNewPremium = () => {
    return (
      <Form.Item
        name={labelMessage.MESSAGE_NEW_SALARY}
        rules={[
          ({ getFieldValue }) => ({
            validator(_, value) {
              let srt: any = Number(value);
              let is_str: any = Number(input_current);

              switch (titleType) {
                case createScheduleInsurances.INCREASE_PREMIUM:
                  if (srt === 0) {
                    return Promise.reject(new Error(t('new_salary_required')));
                  } else if (srt >= is_str) {
                    return Promise.resolve();
                  } else {
                    return Promise.reject(new Error(t('salary_greater_current')));
                  }
                case createScheduleInsurances.DECREASE_PREMIUM:
                  if (value === null && srt === 0) {
                    return Promise.reject(new Error(t('new_salary_required')));
                  } else if (srt > is_str) {
                    return Promise.reject(new Error(t('salary_smaller_current')));
                  } else {
                    return Promise.resolve();
                  }
              }
            },
          }),
        ]}
        style={{ margin: 0 }}
      >
        <InputNumber
          defaultValue={input_newsalary}
          min={0}
          formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          parser={(value: any) => value.replace(/\$\s?|(,*)/g, '')}
          onChange={handleNewSalary}
          style={{ width: '100%', height: 32, fontSize: 14, borderRadius: 6 }}
          maxLength={16}
        />
      </Form.Item>
    );
  };
  const handleKeypress = (e: React.KeyboardEvent<HTMLElement>) => {
    if (e.key === 'Enter') {
      dispatch(
        getEmployeeSearch({
          keyword: input_ID,
          status: activekey === 'increase' ? 'approved' : '',
        }),
      );
    }
  };

  const onSearch = (searchText: any) => {
    dispatch(
      getEmployeeSearch({
        keyword: searchText,
        status: activekey === 'increase' ? 'approved' : '',
      }),
    );
  };
  useEffect(() => {
    if (Array.isArray(checkUserId?.result)) {
      let obj: any = [];
      obj = checkUserId?.result.map((item: any) => ({
        ...mockVal(item),
      }));
      setOptions(obj || []);
    }
  }, [checkUserId]);

  const onSelect = (data: any, obj: any) => {
    if (Array.isArray(checkUserId?.result)) {
      // eslint-disable-next-line array-callback-return
      checkUserId?.result.map((item: any) => {
        if (item.id === obj?.value) {
          const checkSalary =
            item?.currentSalary === null || item?.currentSalary === '' ? true : false;
          setIsSocialNumberAlready(item.socialInsuranceNumber ? true : false);
          setInputName(item?.username);
          setInputID(item?.employeeId);
          setIDUser(item?.id);
          setInputPosition(item?.positionName);
          setInputSocial(item?.socialInsuranceNumber);
          setInputValidDate(item?.currentValidDate);
          setInputCurrrent(checkSalary ? 0 : item?.currentSalary);
          setSelectedUser(item);
          setInputDate(undefined);
          setInputNewSalary(0);

          if (checkSalary || item?.currentSalary <= 0) {
            setRadioValue(createScheduleInsurances.INCREASE_PREMIUM);
          } else {
            if (activekey === 'decrease') {
              setRadioValue(createScheduleInsurances.DECREASE_PREMIUM);
            } else {
              setRadioValue(createScheduleInsurances.INCREASE_PREMIUM);
            }
          }

          if (activekey === 'decrease') {
            setCheckedList([]);
          } else {
            setCheckedList([1, 3, 2, 4]);
          }

          form.setFieldsValue({
            [labelMessage.MESSAGE_NEW_SALARY]: 0,
            [t('social_insurance_number')]: item?.socialInsuranceNumber,
            [t('new_valid_date')]: undefined,
          });
        }
      });
    }
  };

  const disabledDateBeforeContractDate = (current: moment.Moment) => {
    if (!selectedUser || !selectedUser.startDate) return false;

    return current && moment(current) < moment(selectedUser.startDate).add(-1, 'days').endOf('day');
  };

  return (
    <div onKeyPress={handleKeypress}>
      <InsuranceModal
        title={t('create_schedule')}
        visible={isVisible}
        maskClosable={false}
        onCancel={() => handleClose(true)}
        width={iswidth}
        style={{ top: 40 }}
        footer={null}
      >
        <Form form={form} name="control-hooks" onFinish={onFinish}>
          <Container>
            <ContainerTop>
              <InfoRow gutter={[16, 16]}>
                <Col span={12}>
                  <InfoTitle>
                    {t('name')} <InfoU>*</InfoU>
                  </InfoTitle>
                  <Form.Item
                    rules={[{ required: true, message: `${t('name_required')}` }]}
                    name={t('name')}
                    style={{ margin: 0 }}
                  >
                    <Select
                      showSearch
                      allowClear
                      onClear={() => {
                        dispatch(
                          getEmployeeSearch({
                            keyword: '',
                            status: activekey === 'increase' ? 'approved' : '',
                          }),
                        );
                        handleClose(false);
                      }}
                      filterOption={false}
                      style={{
                        width: '100%',
                      }}
                      value={id_user || undefined}
                      onChange={onSelect}
                      onSearch={(value: any) => debounced(value)}
                      placeholder={t('name')}
                    >
                      {options?.map((item: any) => (
                        <Select.Option value={item?.key}>{item?.value}</Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <InfoTitle>
                    {createScheduleInsurances.ID} <InfoU>*</InfoU>
                  </InfoTitle>
                  <InfoInput
                    value={input_ID}
                    disabled
                    style={{ background: colors.gray300 }}
                    onChange={(e: any) => setInputID(e.target.value)}
                    type="number"
                  />
                </Col>
              </InfoRow>
              <InfoRow gutter={[16, 16]}>
                <Col span={12}>
                  <InfoTitle>{t('position')}</InfoTitle>
                  <InfoInput
                    value={input_position}
                    disabled
                    style={{ background: colors.gray300 }}
                    onChange={(e: any) => setInputPosition(e.target.value)}
                    type="text"
                  />
                </Col>
                <Col span={12}>
                  <InfoTitle>
                    {t('social_insurance_number')} <InfoU>*</InfoU>
                  </InfoTitle>
                  <Form.Item
                    rules={[
                      { required: true, message: `${t('social_insurance_number_required')}` },
                    ]}
                    name={t('social_insurance_number')}
                    style={{ margin: 0 }}
                  >
                    <InfoInput
                      value={input_social}
                      disabled={input_social && isSocialNumberAlready ? true : false}
                      style={{
                        background: `${
                          input_social && isSocialNumberAlready ? colors.gray300 : ''
                        }`,
                      }}
                      onChange={(e: any) => setInputSocial(e.target.value)}
                      type="text"
                    />
                  </Form.Item>
                </Col>
              </InfoRow>
              <InfoRow gutter={[16, 16]}>
                <Col span={12}>
                  <InfoTitle>{t('current_salary_premium')}</InfoTitle>
                  <InfoInput
                    value={formatCurrencyUnit(input_current, labelUnit)}
                    disabled
                    style={{ background: colors.gray300 }}
                    onChange={(e: any) => setInputCurrrent(e.target.value)}
                    type="text"
                  />
                </Col>
                <Col span={12}>
                  <InfoTitle>{t('last_join_social_insurance_date')}</InfoTitle>
                  <InfoInput
                    value={input_validdate ? formatDateInsurances(input_validdate) : ''}
                    disabled
                    style={{ background: colors.gray300 }}
                    onChange={(e: any) => setInputValidDate(e.target.value)}
                    type="text"
                  />
                </Col>
              </InfoRow>
              <InfoRow gutter={[16, 16]}>
                <Col span={12}>
                  <InfoTitle>{t('adjust_for')}</InfoTitle>
                  <InfoRadio.Group onChange={handleRadio} value={radiovalue}>
                    <InfoRadio value={createScheduleInsurances.INCREASE_PREMIUM}>
                      {t('increase_premium')}
                    </InfoRadio>
                    <InfoRadio
                      value={createScheduleInsurances.DECREASE_PREMIUM}
                      style={{ marginTop: 6 }}
                      // disabled={input_current <= 0 ? true : false}
                    >
                      {t('decrease_premium')}
                    </InfoRadio>
                  </InfoRadio.Group>
                </Col>
                <Col span={12}>
                  <InfoTitle>{t('insurance_policy')}</InfoTitle>
                  <div style={{ height: 80, width: '100%', overflowY: 'scroll' }}>
                    <div style={{ padding: 10 }}>
                      <Checkbox.Group onChange={onChange} value={checkedList}>
                        {data !== undefined
                          ? data.map((item: any) => (
                              <InfoCheckbox
                                key={item?.id}
                                style={{ margin: -0.5 }}
                                value={item?.insuranceCode}
                              >
                                {item?.insuranceName}
                              </InfoCheckbox>
                            ))
                          : null}
                      </Checkbox.Group>
                    </div>
                  </div>
                </Col>
              </InfoRow>
              <InfoRow gutter={[16, 16]}>
                <Col span={24}>
                  <InfoTitle>
                    {t('type_of') + ' ' + textTitle} <InfoU>*</InfoU>
                  </InfoTitle>
                  <InfoSelect
                    defaultValue={input_TypeOf}
                    onChange={handleChange}
                    value={input_TypeOf}
                  >
                    {is_dataTypeOfIncrease &&
                      is_dataTypeOfIncrease.map((item: any) => (
                        <Option value={item} key={item}>
                          {stringCamelCase(item)}
                        </Option>
                      ))}
                  </InfoSelect>
                </Col>
              </InfoRow>
              <InfoRow gutter={[16, 16]}>
                <Col span={12}>
                  <InfoTitle>
                    {t('new_salary_premium')} <InfoU>*</InfoU>
                  </InfoTitle>
                  {validatorNewPremium()}
                </Col>
                <Col span={12}>
                  <InfoTitle>
                    {t('new_valid_date')} <InfoU>*</InfoU>
                  </InfoTitle>
                  <Form.Item
                    rules={[{ required: true, message: `${t('new_valid_date_required')}` }]}
                    name={t('new_valid_date')}
                    style={{ margin: 0 }}
                  >
                    <InfoDatePicker
                      value={input_date}
                      onChange={handleOnChangeDate}
                      format={'DD/MM/YYYY'}
                      disabledDate={disabledDateBeforeContractDate}
                    />
                  </Form.Item>
                </Col>
              </InfoRow>
              <InfoRow gutter={[16, 16]}>
                <Col span={24}>
                  <InfoTitle>{t('note')}</InfoTitle>
                  {/*<Form.Item name={t('note')} rules={[{ required: true }]}>*/}
                  <TextArea
                    rows={2}
                    value={input_note}
                    onChange={(e: any) => setInputNote(e.target.value)}
                    placeholder={'Note'}
                    style={{
                      padding: 6,
                      width: '100%',
                      borderRadius: 6,
                      color: '#111827',
                    }}
                  />
                  {/*</Form.Item>*/}
                </Col>
              </InfoRow>
            </ContainerTop>
            <ContainerButton>
              <InfoButtonReject onClick={handleSaveDraft}>{t('save_draft')}</InfoButtonReject>
              <InfoButton onClick={handleSendRequest} htmlType="submit">
                {t('send_request')}
              </InfoButton>
            </ContainerButton>
          </Container>
        </Form>
      </InsuranceModal>
    </div>
  );
};

export default React.memo(ModalCreate);
export const InsuranceModal = styled(Modal)`
  .ant-modal-header {
    background: linear-gradient(263.81deg, #f1592a -3.68%, #f57644 45.38%, #fa9b66 108.73%);
  }

  .ant-modal-title {
    color: #ffffff;
    //color: ${colors.gray900};
    font-size: 20px;
  }

  .ant-modal-close-x {
    color: #ffffff;
    //color: ${colors.gray900};
    font-size: 20px;
  }
  .ant-modal-body {
    margin-right: -4.29%;
  }

  .ant-modal-content {
    width: $iswidth;

    .ant-modal-close-x {
      margin-right: -10px;
    }

    .ant-modal-body {
      margin-top: -25px;
    }
  }
`;
export const InfoCheckbox = styled(Checkbox)`
  .ant-checkbox-checked .ant-checkbox-inner {
    background: #f1592a;
    border-color: #f1592a;
  }
`;
export const InfoRadio = styled(Radio)`
  .ant-radio-disabled .ant-radio-inner {
    border-color: #f1592a;
  }
  .ant-radio-inner::after {
    background: #f1592a;
    border-color: #f1592a;
  }
`;
export const InfoInput = styled(Input)`
  height: 32px;
  font-size: 14px;
  border-radius: 6px;
`;

export const InfoDatePicker = styled(DatePicker)`
  width: 100%;
  border-radius: 6px;
`;
export const InfoRow = styled(Row)`
  margin-top: -3px;
`;
export const InfoSelect = styled(Select)`
  width: 100%;
  border-radius: 100px;
`;
export const Container = styled.div`
  height: 620px;
  padding-right: 5%;
`;
export const InfoTitle = styled.div`
  margin: 2px 0px;
  color: #000000;
  font-size: 14px;
  font-family: Roboto;
`;
export const InfoText = styled.div`
  //height: 20px;
  //margin-top: 15px;
  color: #000000;
  font-size: 16px;
`;
export const ContainerTop = styled.div`
  height: 92%;
`;
export const ContainerLoading = styled.div`
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
`;
